import React, { Component } from 'react';
import {Table, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {UID} from 'react-uid';
import {MetricFilter} from './Filters';

const getImage = function (images) {
    for (var key in images) {
        if (images.hasOwnProperty(key)) {
            return images[key];
        }
    }
};

const getSoldDates = function (dates) {
    var sold = 0;
    for (var i in dates) {
        if (dates[i] <= 7){
            sold++;
        }else{
            break;
        }
    }
    return sold;
};


const showText = function (text) {
  return (
      <UID>
          {id => (
          <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                  <Tooltip id={id}>
                      {text}
                  </Tooltip>
              }
          >
              <span>{text.substr(0, 50)}</span>
          </OverlayTrigger>
              )}
      </UID>
  );
};

const showStoreMetrics = function (product) {
    if (product.store === "AMAZON"){
        return (
            <div>
                { product.extras.ratingStars && product.extras.ratingStars !==0 ? <span>Stars: {product.extras.ratingStars}<br/></span> : null }
                { product.extras.ratingNumbers && product.extras.ratingNumbers !== 0 ? <span>Rating: {product.extras.ratingNumbers}<br/></span> : null }
                { product.extras.salesRank && product.extras.salesRank !== 0 ? <span>Rank: {product.extras.salesRank}<br/></span> : null }
                { product.extras.newCount && product.extras.newCount !== 0 ? <span>New: {product.extras.newCount}<br/></span> : null}
            </div>
        );
    }
};

const showMatch = function (result) {
    if (result.match !== undefined){
        return (
            <div>
                { result.match.SKUStore && result.match.SKUStore !== -1 && result.match.SKUStore !== 0 ? <span>SKU: {result.match.SKUStore}<br/></span> : null}
                { result.match.UPCorEAN && result.match.UPCorEAN !== -1 && result.match.UPCorEAN !== 0  ? <span>UPC: {result.match.UPCorEAN}<br/></span> : null  }
                { result.match.Brand && result.match.Brand !== -1 && result.match.Brand !== 0 ? <span>Brand: {result.match.Brand}<br/></span> : null  }
                { result.match.MPN && result.match.MPN !== -1 && result.match.MPN !== 0 ? <span>MPN: {result.match.MPN}<br/></span> : null }
                { result.match.TitleMPNBrand && result.match.TitleMPNBrand !== -1  && result.match.TitleMPNBrand !== 0 ? <span>MPN: {result.match.TitleMPNBrand}<br/></span> : null }
                { result.match.Category && result.match.Category !== -1 && result.match.Category !== 0 ? <span>Category: {result.match.Category}<br/></span> : null }
            </div>
        );
    }
};

class TableRow extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.data.taskID}</td>
                <td>{this.props.data.sourceProduct.store} <hr/> {this.props.data.targetProduct.store}</td>
                <td>{this.props.data.profit}</td>
                <td>
                    <a href={this.props.data.sourceProduct.url} target="_blank" rel="noopener noreferrer">{this.props.data.sourceProduct.sku}</a>
                    <hr/>
                    <a href={this.props.data.targetProduct.url} target="_blank" rel="noopener noreferrer">{this.props.data.targetProduct.sku}</a>
                </td>
                <td>{showText(this.props.data.sourceProduct.title)} <hr/> {showText(this.props.data.targetProduct.title)}</td>
                <td>{this.props.data.sourceProduct.price} <hr/> {this.props.data.targetProduct.price}</td>
                <td>{this.props.data.sourceProduct.shipping} <hr/> {this.props.data.targetProduct.shipping}</td>
                <td>{this.props.data.sold}</td>
                <td>{this.props.data.lastSold}</td>
                <td><img src={getImage(this.props.data.sourceProduct.images)} alt="product" width={"75px"} height={"75px"} onClick={() => {this.props.showImage(getImage(this.props.data.sourceProduct.images), getImage(this.props.data.targetProduct.images))}}/></td>
                <td><img src={getImage(this.props.data.targetProduct.images)} alt="product" width={"75px"} height={"75px"} onClick={() => {this.props.showImage(getImage(this.props.data.sourceProduct.images), getImage(this.props.data.targetProduct.images))}}/></td>
                <td>{showStoreMetrics(this.props.data.targetProduct)}</td>
                <td>{showMatch(this.props.data)}</td>
                <td>{new Date(this.props.data.createdAt).toLocaleDateString()}</td>
                {/*<td>{this.props.data.targetProduct.sku}</td>*/}
                {/*<td>{this.props.data.targetProduct.store}</td>*/}
                {/*<td><a href={this.props.data.targetProduct.url} rel="noopener noreferrer" target="_blank">{this.props.data.targetProduct.url}</a></td>*/}
                {/*<td>{this.props.data.targetProduct.price}</td>*/}
            </tr>
        );
    }
}


class OrderType extends Component{
    render() {
        return (
            <span>
            { this.props.type === "-" ?
                (<FontAwesomeIcon icon="arrow-down"/>
                ) : (
                    <FontAwesomeIcon icon="arrow-up"/>)
            }
            </span>
        );
    }
}

export default class ResultsTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            showImageModal: false,
            sourceImage: "",
            targetImage: "",
            isMetricFilterModalShow: false,
        };
        this.updateOrderClick = this.updateOrderClick.bind(this);
        this.modalImageClose = this.modalImageClose.bind(this);
        this.openImageModal = this.openImageModal.bind(this);
        this.onMetricFilter = this.onMetricFilter.bind(this);
        this.modalFilterClose = this.modalFilterClose.bind(this);


    }

    updateOrderClick(event) {
        let order = event.currentTarget.dataset['kind'];
        let value = "";
        if(this.props.orderType === ""){
            value = "-";
        }
        this.props.OnUpdateOrder(value, order);
        event.preventDefault();
    }

    openImageModal = (sourceImage, targetImage) => {
        this.setState({showImageModal: true, sourceImage: sourceImage, targetImage: targetImage})
    };

    modalImageClose() {
        this.setState({showImageModal: false, value: "", url: ""})
    }


    openFilterModal = (event) => {
        this.setState({isMetricFilterModalShow: true});
        event.preventDefault();
    };

    modalFilterClose() {
        this.setState({isMetricFilterModalShow: false});
    };


    onMetricFilter(filters) {
        console.log("on metric filter");
        this.setState({isMetricFilterModalShow: false}, this.props.onMetricFilter(filters));
    }

    render() {
        return (
            <div>
                <Modal show={this.state.showImageModal} onHide={this.modalImageClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m">
                            Source image:
                        </div>
                        <div>
                            <img src={this.state.sourceImage} alt="product" width={"450px"} />
                        </div>
                        <div className="m">
                            Target image:
                        </div>
                        <div>
                            <img src={this.state.targetImage} alt="product" width={"450px"} />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isMetricFilterModalShow} onHide={this.modalFilterClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Metrics Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MetricFilter onUpdate={this.onMetricFilter}/>
                    </Modal.Body>
                </Modal>
                <Table striped bordered responsive hover>
                    <thead>
                    <tr>
                        <th>Task ID</th>
                        <th>Marketplace</th>
                        <th><a href="/" data-kind="profit" onClick={this.updateOrderClick}>Profit {this.props.order === "profit" && <OrderType type={this.props.orderType} /> }</a></th>
                        <th>SKU</th>
                        <th>Title</th>
                        <th><a href="/" data-kind="sourceproduct.price" onClick={this.updateOrderClick}>Price {this.props.order === "sourceproduct.price" && <OrderType type={this.props.orderType} /> }</a></th>
                        <th><a href="/" data-kind="sourceproduct.shipping" onClick={this.updateOrderClick}>Shipping {this.props.order === "sourceproduct.shipping" && <OrderType type={this.props.orderType} /> }</a></th>
                        <th><a href="/" data-kind="sold" onClick={this.updateOrderClick}>Sold Overall{this.props.order === "sold" && <OrderType type={this.props.orderType} /> }</a></th>
                        <th><a href="/" data-kind="lastsold" onClick={this.updateOrderClick}>Sold in last 7 days{this.props.order === "lastsold" && <OrderType type={this.props.orderType} /> }</a></th>
                        <th>Image</th>
                        <th>Supplier Image</th>
                        <th>Store Metrics <a href="/" onClick={this.openFilterModal}><FontAwesomeIcon icon="cog"/></a></th>
                        <th>Match Percent</th>
                        <th><a href="/" data-kind="createdat" onClick={this.updateOrderClick}>Found Date {this.props.order === "createdat" && <OrderType type={this.props.orderType} /> }</a></th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((product, i) => <TableRow key={i} data={product} showImage={this.openImageModal}/>)}
                    </tbody>
                </Table>
            </div>
        );
    }
}