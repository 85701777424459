import React, { Component } from 'react';
import './App.css';
import './ResultsTable';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import {Button, Container, Pagination, Spinner, ButtonToolbar, Modal} from 'react-bootstrap';
import Navigation from './Navigation';
import ResultsTable from './ResultsTable';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowDown, faCog } from '@fortawesome/free-solid-svg-icons'
import { ProfitFilter } from "./Filters";


library.add(faArrowUp, faArrowDown, faCog);

//const SERVICE_URL = "http://127.0.0.1:9001";

let SERVICE_URL = "http://opportunity-finder.dev.skuio.com";


//const SERVICE_URL = "http://opportunity-finder.dev.skuio.com/api";

const defaultExcludeFields = JSON.stringify(["description"]);


class AppPagination extends Component {
    render(){
        return (
            <div>
                <Pagination>
                    { this.props.page > 2 ?<Pagination.First onClick={this.props.onFirst} />  : null }
                    { this.props.page !== 1 ? <Pagination.Prev onClick={this.props.onPrevPage} /> : null}
                    { this.props.page > 3 ?  <Pagination.Item onClick={() => {this.props.onPage(this.props.page - 2)}} key={this.props.page - 2}>{this.props.page - 2}</Pagination.Item> : null }
                    { this.props.page > 2 ?  <Pagination.Item onClick={() => {this.props.onPage(this.props.page - 1)}} key={this.props.page - 1}>{this.props.page - 1}</Pagination.Item> : null }
                    <Pagination.Item key={this.props.page} active="active">
                        {this.props.page}
                    </Pagination.Item>
                    { this.props.count > 0 ?  <Pagination.Item onClick={() => {this.props.onPage(this.props.page + 1)}} key={this.props.page + 1}>{this.props.page + 1}</Pagination.Item> : null }
                    { this.props.count > 0 ?  <Pagination.Item onClick={() => {this.props.onPage(this.props.page + 2)}} key={this.props.page + 2}>{this.props.page + 2}</Pagination.Item> : null }
                    { this.props.count > 0 ? <Pagination.Next onClick={this.props.onNextPage}/> : null }
                </Pagination>
            </div>
        );
    }
}

class App extends Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(window.location.search);
        this.state = {
            data: [],
            additional: {
                soldItems: 0,
                categories: 0,
                profitItems: 0,
            },
            page: query.get("page") || 1,
            countFilter: 0,
            filters: query.get("filters") || {
            },
            metricsFilter: query.get("metrics") || [],
            isLoading: true,
            sort: query.get("sort") || "-createdat",
            order: query.get("order") || "createdat",
            orderType: query.get("orderType") || "-",
            isFilterModalShow: false,
        };

        this.handleFilterModalClose = this.handleFilterModalClose.bind(this);
        this.handleFilterModalShow = this.handleFilterModalShow.bind(this);
    }

    componentDidMount() {
        if (window.location.hostname !== "opportunity-finder.dev.skuio.com"){
            SERVICE_URL = "https://opportunity-finder.skuio.com/api"
        }

        // send request to server
        this.fetchProducts();
        this.fetchVersion();
    }

    fetchVersion = () => {
        fetch(`${SERVICE_URL}/v1/version`).then(response => response.json()).then(
            result => {
                this.setState({additional: {soldItems: result.data.soldItems, categories: result.data.categories, profitItems: result.data.profitItems}});
            }
        )
    };

    fetchProducts = () => {
        let page = this.state.page;
        const params = new URLSearchParams(window.location.search);
        params.set("page", page);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
        fetch(this.getRequestUrl(page))
            .then(response => response.json())
            .then(
                result =>
                {
                    if (result.data == null)
                    {
                        result.data = [];
                    }
                    if (result.count == null){
                        result.count = 0;
                    }

                    this.setState({data: result.data, page: page, isLoading: false, countFilter: result.count})
                })
            .catch(e => console.log(e));
    };

    nextPage = () => {
        this.setState({isLoading: true});
        const page = this.state.page + 1;
        const params = new URLSearchParams(window.location.search);
        params.set("page", page);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
        fetch(this.getRequestUrl(page))
            .then(response => response.json())
            .then(result => {if (result.data == null) {result.data = [];} this.setState({data: result.data, page: page, isLoading: false})})
            .catch(e => console.log(e));
    };


    getRequestUrl = (page) => {
        let basic = `${SERVICE_URL}/v1/results?page=${page}&limit=50&excludeFields=${defaultExcludeFields}&sort=${this.state.sort}&filters=${JSON.stringify(this.state.filters)}&metricFilter=${JSON.stringify(this.state.metricsFilter)}`;
        // for (const [key, value] of Object.entries(this.state.filters)) {
        //     basic += `&${key}=${value}`;
        // }
        return basic
    };

    prevPage = () => {
        this.setState({isLoading: true});
        const page = this.state.page - 1;
        const params = new URLSearchParams(window.location.search);
        params.set("page", page);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
        fetch(this.getRequestUrl(page))
            .then(response => response.json())
            .then((result) => { if (result.data == null) {result.data = [];} this.setState({data: result.data, page: page, isLoading: false})})
            .catch(e => console.log(e));
    };

    firstPage = () => {
        this.setState({isLoading: true});
        const page = 1;
        const params = new URLSearchParams(window.location.search);
        params.set("page", page);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
        fetch(this.getRequestUrl(page))
            .then(response => response.json())
            .then((result) => { if (result.data == null) {result.data = [];} this.setState({data: result.data, page: page, isLoading: false})})
            .catch(e => console.log(e));
    };

    onPage = (newPage) => {
        this.setState({isLoading: true});
        const page = newPage;
        const params = new URLSearchParams(window.location.search);
        params.set("page", page);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
        fetch(this.getRequestUrl(page))
            .then(response => response.json())
            .then((result) => { if (result.data == null) {result.data = [];} this.setState({data: result.data, page: page, isLoading: false})})
            .catch(e => console.log(e));
    };

    setFilters = (filters) => {
        this.setState({filters: filters, isFilterModalShow: false, isLoading: true}, this.fetchProducts);
        const params = new URLSearchParams(window.location.search);
        params.set("filters", filters);
        // params.set("maxProfit", maxProfit);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
    };

    setMetricsFilter = (filters) => {
        console.log("filters", filters);
        this.setState({metricsFilter: filters, isFilterModalShow: false, isLoading: true}, this.fetchProducts);
        const params = new URLSearchParams(window.location.search);
        params.set("metrics", JSON.stringify(filters));
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
    };

    setOrders = (kind, order) => {
        let orderValue = `${kind}${order}`;
        this.setState({order: order, orderType: kind, sort: orderValue, isLoading: true}, this.fetchProducts);
        //this.fetchProducts();
        const params = new URLSearchParams(window.location.search);
        params.set("sort", orderValue);
        params.set("order", order);
        params.set("orderType", kind);
        window.history.pushState({}, '', `${window.location.pathname}?${params}`);
    };

    handleFilterModalClose() {
        this.setState({ isFilterModalShow: false });
    }

    handleFilterModalShow() {
        this.setState({ isFilterModalShow: true });
    }

  render() {
    return (
      <div>
          <Modal show={this.state.isFilterModalShow} onHide={this.handleFilterModalClose}>
              <Modal.Header closeButton>
                  <Modal.Title>Filters</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <ProfitFilter onUpdate={this.setFilters} filters={this.state.filters} />
              </Modal.Body>
          </Modal>
          <Navigation />
          <Container fluid={true}>
              <ButtonToolbar className="m-2">
                  <Button variant="primary" onClick={this.handleFilterModalShow}>Add Filters</Button>
              </ButtonToolbar>
              { this.state.isLoading && <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>}
              In last 24 hours processed {this.state.additional.soldItems} sold items at eBay in over {this.state.additional.categories} categories.
              Found {this.state.additional.profitItems} matches with profit greater than 0.
              <br/>
              { this.state.countFilter > 0 && <span>Based on your filters, we have found {this.state.countFilter} matches.</span> }
              <ResultsTable data={this.state.data}
                            OnUpdateOrder={this.setOrders}
                            maxProfit={this.state.maxProfit}
                            minProfit={this.state.minProfit}
                            orderType={this.state.orderType}
                            order={this.state.order}
                            onMetricFilter={this.setMetricsFilter}/>
              { this.state.isLoading && <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>}

                  <AppPagination page={this.state.page} count={this.state.data.length} onPage={this.onPage} onNextPage={this.nextPage} onFirst={this.firstPage} onPrevPage={this.prevPage}/>

           </Container>
      </div>
    );
  }
}

export default App;
