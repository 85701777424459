import React, { Component } from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import DayPickerInput from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export class ProfitFilter extends Component{
    constructor(props) {
        super(props);
        this.state = {
            maxProfit: this.props.filters.maxProfit,
            minProfit: this.props.filters.minProfit,
            selectedDay: this.props.filters.selectedDay,
            sourcePriceFrom: this.props.filters.sourcePriceFrom,
            sourcePriceTo: this.props.filters.sourcePriceTo,
            targetPriceFrom: this.props.filters.targetPriceFrom,
            targetPriceTo: this.props.filters.targetPriceTo,
            soldOverallFrom: this.props.filters.soldOverallFrom,
            soldOverallTo: this.props.filters.soldOverallTo,
            soldFrom: this.props.filters.soldFrom,
            soldTo: this.props.filters.soldTo,
            store: this.props.filters.store,
            SKUStoreFrom: this.props.filters.SKUStoreFrom,
            SKUStoreTo: this.props.filters.SKUStoreTo,
            UPCorEANFrom: this.props.filters.UPCorEANFrom,
            UPCorEANTo: this.props.filters.UPCorEANTo,
            brandFrom: this.props.filters.brandFrom,
            brandTo: this.props.filters.brandTo,
            MPNFrom: this.props.filters.MPNFrom,
            MPNTo: this.props.filters.MPNTo,
            titleMPNBrandFrom: this.props.filters.titleMPNBrandFrom,
            titleMPNBrandTo: this.props.filters.titleMPNBrandTo,
            categoryTo: this.props.filters.categoryTo,
            categoryFrom: this.props.filters.categoryFrom,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleDayClick(day, { selected }) {
        this.setState({
            selectedDay: selected ? undefined : day,
        });
    }

    handleSubmit(event) {
        this.props.onUpdate(this.state);
        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.type === 'number'){
            value = parseFloat(target.value)
        }

        this.setState({
            [name]: value
        });
    }

    render(){
        return (
            <div className="m-2">
                <Form>
                    <Row>
                        <Col>
                            <Form.Label>Min Profit</Form.Label>
                            <Form.Control type="number" placeholder="Enter min profit" name="minProfit" onChange={this.handleInputChange} value={this.state.minProfit}/>
                        </Col>
                        <Col>
                            <Form.Label>Max Profit</Form.Label>
                            <Form.Control type="number" placeholder="Enter max profit" name="maxProfit" value={this.state.maxProfit} onChange={this.handleInputChange} />
                        </Col>

                    </Row>
                    <Form.Label>eBay Price</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="sourcePriceFrom" onChange={this.handleInputChange} value={this.state.sourcePriceFrom}/>
                        </Col>
                        <Col>
                        <Form.Control type="number" placeholder="To" name="sourcePriceTo" onChange={this.handleInputChange} value={this.state.sourcePriceTo}/>
                        </Col>
                    </Row>
                    <Form.Label>Store Price</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="targetPriceFrom" onChange={this.handleInputChange} value={this.state.targetPriceFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="targetPriceTo" onChange={this.handleInputChange} value={this.state.targetPriceTo}/>
                        </Col>
                    </Row>
                    <Form.Label>Found Date</Form.Label>
                    <Row>
                        <DayPickerInput selectedDays={this.state.selectedDay} onDayClick={this.handleDayClick} />
                    </Row>
                    <Form.Label>Sold Overall</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="soldOverallFrom" onChange={this.handleInputChange} value={this.state.soldOverallFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="soldOverallTo" onChange={this.handleInputChange} value={this.state.soldOverallTo}/>
                        </Col>
                    </Row>
                    <Form.Label>Sold In Last 7 days</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="soldFrom" onChange={this.handleInputChange} value={this.state.soldFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="soldTo" onChange={this.handleInputChange} value={this.state.soldTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Sold In Last 7 days</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="soldFrom" onChange={this.handleInputChange} value={this.state.soldFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="soldTo" onChange={this.handleInputChange} value={this.state.soldTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Match Percent by SKU Store</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="SKUStoreFrom" onChange={this.handleInputChange} value={this.state.SKUStoreFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="SKUStoreTo" onChange={this.handleInputChange} value={this.state.SKUStoreTo}/>
                        </Col>
                    </Row>


                    <Form.Label>Match Percent by UPC or EAN</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="UPCorEANFrom" onChange={this.handleInputChange} value={this.state.UPCorEANFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="UPCorEANTo" onChange={this.handleInputChange} value={this.state.UPCorEANTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Match Percent by Brand</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="brandFrom" onChange={this.handleInputChange} value={this.state.brandFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="brandTo" onChange={this.handleInputChange} value={this.state.brandTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Match Percent by MPN</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="MPNFrom" onChange={this.handleInputChange} value={this.state.MPNFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="MPNTo" onChange={this.handleInputChange} value={this.state.MPNTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Match Percent by Title and MPN</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="titleMPNBrandFrom" onChange={this.handleInputChange} value={this.state.titleMPNBrandFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="titleMPNBrandTo" onChange={this.handleInputChange} value={this.state.titleMPNBrandTo}/>
                        </Col>
                    </Row>

                    <Form.Label>Match Percent by Category</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder="From" name="categoryFrom" onChange={this.handleInputChange} value={this.state.categoryFrom}/>
                        </Col>
                        <Col>
                            <Form.Control type="number" placeholder="To" name="categoryTo" onChange={this.handleInputChange} value={this.state.categoryTo}/>
                        </Col>
                    </Row>

                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Store</Form.Label>
                        <Form.Control as="select" defaultValue={this.state.store} name="store" onChange={this.handleInputChange}>
                            <option value="">All</option>
                            <option value="AMAZON">Amazon</option>
                        </Form.Control>
                    </Form.Group>


                    <Button variant="primary" type="submit" className="mt-1" onClick={this.handleSubmit}>
                        Submit filter
                    </Button>
                </Form>
            </div>
        );
    }
}

export class MetricFilter extends Component{
    constructor(props) {
        super(props);
        this.state = {
          store: "AMAZON", metricsFilters: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.getAmazon = this.getAmazon.bind(this);
    }


    getAmazon() {
        return (
            <div className={this.state.store !== "AMAZON" ? "blocked" : "active"}>
                <Form.Label>Rating Stars</Form.Label>
                <Row>
                    <Col>
                        <Form.Control type="number" placeholder="From" data-store={"AMAZON"} data-name={"ratingStars"} data-type="from" name="UPCorEANFrom" />
                    </Col>
                    <Col>
                        <Form.Control type="number" placeholder="To" data-store={"AMAZON"} data-name={"ratingStars"}  data-type="to" name="UPCorEANTo" />
                    </Col>
                </Row>

                <Form.Label>Rating Numbers</Form.Label>
                <Row>
                    <Col>
                        <Form.Control type="number" placeholder="From" data-store={"AMAZON"} data-name={"ratingNumbers"}  data-type="from" name="UPCorEANFrom"/>
                    </Col>
                    <Col>
                        <Form.Control type="number" placeholder="To" data-store={"AMAZON"} data-name={"ratingNumbers"} data-type="to" name="UPCorEANTo"/>
                    </Col>
                </Row>

                <Form.Label>Sales Rank</Form.Label>
                <Row>
                    <Col>
                        <Form.Control type="number" placeholder="From" data-store={"AMAZON"} data-name={"salesRank"}  data-type="from" name="UPCorEANFrom" />
                    </Col>
                    <Col>
                        <Form.Control type="number" placeholder="To" data-store={"AMAZON"} data-name={"salesRank"} data-type="to" name="UPCorEANTo" />
                    </Col>
                </Row>

                <Form.Label>New Count</Form.Label>
                <Row>
                    <Col>
                        <Form.Control type="number" placeholder="From" data-store={"AMAZON"} data-name={"newCount"} data-type="from" name="UPCorEANFrom" />
                    </Col>
                    <Col>
                        <Form.Control type="number" placeholder="To" data-store={"AMAZON"} data-name={"newCount"} data-type="to" name="UPCorEANTo" />
                    </Col>
                </Row>
            </div>
        );
    }

    handleSubmit(event) {
        console.log("elements", event.target.elements);
        var filters = {};
        for (var i = 0; i < event.target.elements.length; i++) {
            if (event.target.elements[i].dataset.name === undefined){
                continue
            }
            let value = event.target.elements[i].value;
            if (value === ""){
                continue
            }

            if (event.target.elements[i].type === 'number'){
                value = parseFloat(value)
            }

            let storeName = event.target.elements[i].dataset.store;
            if (!filters.hasOwnProperty(event.target.elements[i].dataset.store)){
                filters[storeName] = {"store": event.target.elements[i].dataset.store, "values": {}};
            }

            let name = event.target.elements[i].dataset.name;
            let kind = event.target.elements[i].dataset.type;
            if (!filters[storeName]["values"].hasOwnProperty(name)) {
                filters[storeName]["values"][name] = {kind: value}
            }else{
                filters[storeName]["values"][name][kind] = value
            }

            console.log("name", event.target.elements[i].dataset.name);
        }
        // console.log("filters = ", filters);
        this.props.onUpdate(Object.values(filters));
        event.preventDefault();
    }

    handleReset(event) {
        this.props.onUpdate({});
        event.preventDefault();
    }

    renderFilter = () => {
        return this.getAmazon();
    };

    render() {
        return (
            <div>
                <form onSubmit={(event) => {this.handleSubmit(event)}}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Store</Form.Label>
                        <Form.Control as="select" defaultValue={this.state.store} name="store" onChange={this.handleStoreChange}>
                            <option value="AMAZON">Amazon</option>
                        </Form.Control>
                    </Form.Group>


                    {this.renderFilter()}

                    <Button variant="primary" type="submit" className="mt-1">
                        Submit filter
                    </Button>
                    <br/>
                    <Button variant="danger" type="submit" className="mt-1" onClick={(event) => {this.handleReset(event)}}>
                        Reset
                    </Button>
                </form>
            </div>
        );
    }
}